<app-sub-header [colorList]="cecColorList" [subHeaderList]="cecSubHeaderList" [pageName]="'cec'"
    ></app-sub-header>
<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>

    <div *ngIf="weddingBannerData" class="downloadable_techspecs mobileExtraSpace"
        [attr.style]="('--data-techspecs-textColor:#ffffff;  --data-downloadable-desktop-img:url('+ staticUrlForImgNew + weddingBannerData?.UploadImage?.document?.contentUrl +'); --data-downloadable-mobile-img:url('+ staticUrlForImgNew + weddingBannerData?.UploadMobileKMAImage?.document?.contentUrl + ')') | safe: 'style'">
        <div class="breadcrumbs kma_breadcrumbs">
            <div class="breadcrumbs_container">
                <a href='/'>Jio World Centre</a> / <a href='/jio-world-convention-centre'>Jio World Convention
                    Centre</a> / <strong>Weddings</strong>
            </div>
        </div>
        
    </div>
    <div class="banner-new" *ngIf="weddingBannerData?.HideComponentOnSite?.data=='false'">
        <app-banner-new *ngIf="weddingBannerData" [newBannerData]="weddingBannerData"></app-banner-new>
        <!-- <app-banner [landingBannerData]="weddingBannerData?.cards[0]" [bannerPage]="'cec'" [fragmentId]="'about'"
        [sectionClass]="'convention_centre screen_height_inside'" [blackTint]="true"></app-banner> -->
    </div>    

    <app-benefits-wrapper *ngIf="weddingGettingStartedData?.HideComponentOnSite?.data=='false'" [benefitsWrapperData]="weddingGettingStartedData"
        [sectionClass]="'pb-0'" [colorList]="weddingColorList"></app-benefits-wrapper>
    
    <!-- <section class="explorevenues_wrapper pb-0" *ngIf="weddingServicesData?.HideComponentOnSite?.data==='false'"
        id="the-experience" data-mobile-header="The Experience">
        <div class="container">
            <div class="section_heading section_heading--title section_heading--text text-center"
                [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                <h2>{{weddingServicesData?.ComponentTitle?.data}}</h2>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="container">
            <div class="row explorevenues_wrapper_each" [ngClass]="i==0 ?'mt-0':'mt-5'" *ngFor="let item of weddingServicesData?.cards; let i=index"
            [attr.style]="('--data-explore-boxbgColor:' + colorList?.dataExploreBoxBgColor) | safe: 'style'">
                <div class="col-lg-5 col-md-5 p-0">
                  <div class="explorevenues_wrapper_each--img">
                    <img src="{{ staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl }}" class="img-fluid" alt="{{cardData?.ImageAltText?.data}}">
                  </div>
                </div>
                <div class="col-lg-7 col-md-7">
                  <div class="explorevenues_wrapper_each_copy">
                    <h4 [innerHTML]="item?.ComponentHeaderTitle?.data"></h4>
                    <p class="explorevenues_wrapper_each_copy--text content3" [innerHTML]="item?.ComponentHeaderSubTitle?.data"></p>
                    <div class="row safety_icons text-center">
                        <ng-container *ngFor="let icon of item?.AddIcons">
                            <div class="col-md-4 col-sm-6 col-6" *ngIf="icon?.HideIcon?.data!=='true'">
                                <div class="safety_icons_each">
                                  <div class="safety_icons_each--icon">
                                    <img src="{{ staticUrlForImgNew + icon?.Icon?.image?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                                  </div>
                                  <p class="safety_icons_each--text px-3" [innerHTML]="icon?.IconText?.data"></p>
                                </div>
                              </div>
                        </ng-container>    
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </section> -->

    <section class="explorevenues_wrapper pb-0" *ngIf="weddingServicesData?.HideComponentOnSite?.data==='false'"
    id="the-experience" data-mobile-header="The Experience">
    <div class="container">
        <div class="section_heading section_heading--title section_heading--text text-center"
            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
            <h2>{{weddingServicesData?.ComponentTitle?.data}}</h2>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="container">
        <!-- <app-safety-card [cardData]="weddingServicesData" [mainClass]="'mt-0'" [colorList]="weddingColorList">
        </app-safety-card> -->
        <div class="row explorevenues_wrapper_each" [ngClass]="i==0 ?'mt-0':'mt-5'" *ngFor="let item of weddingServicesData?.cards; let i=index"
        [attr.style]="('--data-explore-boxbgColor:' + colorList?.dataExploreBoxBgColor) | safe: 'style'">
            <div class="col-lg-5 col-md-5 p-0">
              <div class="explorevenues_wrapper_each--img">
                <img src="{{ staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl }}" class="img-fluid" alt="{{cardData?.ImageAltText?.data}}">
              </div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div class="explorevenues_wrapper_each_copy">
                <h4 [innerHTML]="item?.ComponentHeaderTitle?.data"></h4>
                <p class="explorevenues_wrapper_each_copy--text content3" [innerHTML]="item?.ComponentHeaderSubTitle?.data"></p>
                <div class="row safety_icons text-center">
                    <ng-container *ngFor="let icon of item?.AddIcons">
                        <div class="col-md-4 col-sm-6 col-6" *ngIf="icon?.HideIcon?.data!=='true'">
                            <div class="safety_icons_each">
                              <div class="safety_icons_each--icon">
                                <img src="{{ staticUrlForImgNew + icon?.Icon?.image?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                              </div>
                              <p class="safety_icons_each--text px-3" [innerHTML]="icon?.IconText?.data"></p>
                            </div>
                          </div>
                    </ng-container>    
                </div>
                <div class="button_links" *ngIf="item?.KnowMoreCTALink?.data">
                    <a [href]="item?.KnowMoreCTALink?.data" class="cta-sec color-blue"
                        (click)="_sharedService.checkCTALink($event, item?.KnowMoreCTALink?.data,'_blank')">
                        <span class="cta-prim-txt">{{item?.KnowMoreCTAText?.data}}</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
    </div>
</section>

    <app-venue-details-showcase *ngIf="cecVenueDetailsShowcaseData"
      [venueDetailsShowcaseData]="cecVenueDetailsShowcaseData" [colorList]="cecVenueDetailsColorList">
    </app-venue-details-showcase>

    <app-organisers-speak *ngIf="organiserData?.HideComponentOnSite?.data==='false'" [organiserData]="organiserData" [colorList]="cecColorList">
    </app-organisers-speak>

    <app-get-in-touch *ngIf="weddingGetInTouchData?.HideComponentOnSite?.data==='false'" [getInTouchData]="weddingGetInTouchData" 
    [colorList]="weddingColorList" [pageName]="'homeWithoutEmpClass'" [downloadCta]="false" [sectionClass]="'pb-0'"></app-get-in-touch>

    <div class="pt-150" *ngIf="weddingServicesDownloadsList?.HideComponentOnSite?.data!='false'"></div>    
    <section class="download_services" id="downloads" data-mobile-header="Downloads" *ngIf="weddingServicesDownloadsList?.HideComponentOnSite?.data=='false'">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="content2">
                        <div class="section_heading section_heading--title section_heading--text multilineText mb-30 pb-0 text-center"
                            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                            <h2>Downloads</h2>
                        </div>
                        <div class="download_services_list">
                            <div class="container">
                                <div class="download_services_list_box">
                                    <div class="row">
                                        <ng-container *ngFor="let item of weddingServicesDownloadsList?.cards; let i=index">
                                            <div *ngIf="item?.HideCTA?.data==='false'" class="download_services_list_box_each">
                                                <a href="{{item?.CTALink?.data}}" (click)="weddingServicesCTEvent('download', '', item?.CTALink?.data, ''); _sharedService.checkCTALink($event, item?.CTALink?.data, '_self', 'PDF', pdfName)"><span class="content2">{{item?.HeaderText?.data}}</span> <img
                                                        src="./assets/images/download_icon.svg" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                                                </a>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<div class="overlay"></div>