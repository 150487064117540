<div class="small-meeting-room-class">
  <!-- <iframe title="s" src="assets/Jio-world-convention-center-fe-develop/home.html" frameborder="0" style="height: 100%; width:100%;"></iframe> -->

  <!-- newhtml
     -->


  <!-- HERO BANNER -->

  <section class="hero_banner_wrapper">
    <img src="assets/Jio-world-convention-center-fe-develop/assets/SMALL_MEETING.png" alt="banner"
      class="hero_banner" />
    <img src="assets/Jio-world-convention-center-fe-develop/assets/SMALL_MEETING.png" alt="banner"
      class="mob_hero_banner" />
    <div class="banner_title_wrapper">
      <h2 class="banner_title">
        Introducing Meeting Pods for Meetings on the go
      </h2>
      <!-- <a href="#"> -->
      <button class="main_button" (click)="scrolltoContent()">EXPLORE</button>
      <!-- </a> -->
    </div>
  </section>

  <!-- MEETING PODS -->
  <section class="meeting_pod_wrapper">
    <div class="pod_subwrapper">
      <div class="pod_image_wrapper">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/metting-room-pod-new.jpg" alt="meeting_pod"
          class="pod_image" />
        <div class="img_border"></div>
      </div>
      <div class="pod_details">
        <div class="line"></div>
        <p class="pod_title">Meeting Pods</p>
        <p class="pod_desc">
          Be it interviews, breakout sessions or workshops, now book our meeting spaces with ease at short notice.
        </p>
      </div>
    </div>

    <div class="area_wrapper">
      <div class="area_subwrapper">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/area.png" alt="area" />
        <div class="area_details">
          <p class="area_title">Up to 650 sq. ft.</p>
          <p class="area_subtitle">Total Area</p>
        </div>
      </div>
      <div class="area_subwrapper">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/suite.png" alt="suite" />
        <div class="area_details">
          <p class="area_title">02</p>
          <p class="area_subtitle">Boardrooms</p>
        </div>
      </div>
      <div class="area_subwrapper">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/rooms.png" alt="rooms" />
        <div class="area_details">
          <p class="area_title">06</p>
          <p class="area_subtitle">Meeting Pods</p>
        </div>
      </div>
    </div>
  </section>

  <!-- DURATION AND COMMERCIAL -->
  <section class="duration_wrapper">
    <div class="duration_header">
      <div class="line"></div>
      <p class="duration_title">Packages</p>
      <!-- <p class="pod_desc">
        Our flexible packages have been specially curated for meetings of all kinds.
      </p> -->
    </div>
    <div class="duration_image_wrapper">
      <img src="assets/Jio-world-convention-center-fe-develop/assets/MEETING-ROOM-DURATIONS-COMMERCIALS.jpg"
        alt="banner" class="duration_image" />

      <div class="duration_details_wrapper">
        <div class="duration_details">
          <p class="duration_detail_title">MEETING PODS FOR UP TO 8 PAX</p>
          <div class="taxes">

            <p class="tax_lable">3-hour Package - INR 5,000*</p>
            <p class="tax_lable">6-hour Package - INR 8,000*</p>
            <p class="tax_lable">9-hour Package - INR 12,000*</p>
            <p class="tax_lable">Additional Hours - INR 2,000/hour*</p>
            <p class="tax_lable">*plus additional taxes</p>

            <!-- <p class="tax_lable">3-hour Package - INR 5,000 plus taxes</p>
            <p class="tax_lable">6-hour Package - INR 8,000 plus taxes</p>
            <p class="tax_lable">9-hour Package - INR 12,000 plus taxes</p>
            <p class="tax_lable">Additional Hours - INR 2,000/hour plus taxes</p> -->
          </div>
          <a href="/assets/Jio-world-convention-center-fe-develop/assets/JWCC-Meeting-Pods-Factsheet.pdf"
            target="_blank"><button class="main_button other_btn">GET DETAILS</button></a>
        </div>
        <div class="duration_details">
          <p class="duration_detail_title">BOARDROOM FOR UP TO 20 PAX</p>
          <div class="taxes">
            <!-- <p class="tax_lable">3-hour Package - INR 15,000 plus taxes</p>
            <p class="tax_lable">6-hour Package - INR 22,500 plus taxes</p>
            <p class="tax_lable">9-hour Package - INR 30,000 plus taxes</p>
            <p class="tax_lable">Additional Hours - INR 5,000/hour plus taxes</p> -->

            <p class="tax_lable">3-hour Package - INR 15,000*</p>
            <p class="tax_lable">6-hour Package - INR 22,500*</p>
            <p class="tax_lable">9-hour Package - INR 30,000*</p>
            <p class="tax_lable">Additional Hours - INR 5,000/hour*</p>
            <p class="tax_lable">*plus additional taxes</p>

          </div>
          <a href="/assets/Jio-world-convention-center-fe-develop/assets/JWCC-Boardroom-Suite-Factsheet.pdf"
            target="_blank"><button class="main_button other_btn">GET DETAILS</button></a>
        </div>
      </div>
    </div>
  </section>

  <!-- INCLUSION -->
  <section class="inclusion_wrapper">
    <div class="inclusion_subwrapper">
      <div class="pod_image_wrapper inclusion_image_wrapper">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/MEETING-ROOM-INCLUSION.jpg" alt="inclusion"
          class="inclusion_image" />
        <div class="inclusion_img_border"></div>
      </div>
      <div class="inclusion_details">
        <div class="inc_header_wrapper">
          <div class="line"></div>
          <p class="inclusion_title">Packages Include</p>
        </div>

        <ul class="list_wrapper">
          <li> Unlimited tea and coffee </li>
          <li> Freshly baked cookies, bottled water and mints</li>
          <li> Writing material </li>
          <li> High speed 5G internet </li>
          <li> In-built 55-inch LED TV with HDMI connectivity</li>
          <li> F&B options available at additional charges</li>
          <li> Video conferencing starting at INR 10,000 plus taxes</li>
        </ul>

        <!-- <div class="inclusion_btns_wrapper">
          <a href="#">
            <button class="main_button other_btn">ENQUIRE NOW</button></a>
          <a href="#"><button class="main_button other_btn">GET DETAILS</button></a>
        </div> -->
      </div>
    </div>
  </section>

  <!-- EVENTS WE HOST -->
  <!-- <section class="host_wrapper">
    <div class="line"></div>
    <p class="host_title">Events We Host</p>

    <div class="host_subwrapper">
      <div class="host_accordian">
        <div class="accordian">
          <div class="qtn" onclick="handleAccordian(this, 'arrow_1', 'assets/Jio-world-convention-center-fe-develop/assets/host.png')" id="first_qtn">
            <p class="qtn_title">Meetings</p>
            <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/arrow.png" alt="arrow" class="arrow" id="arrow_1" />
          </div>
          <div class="ans">
            <p>
              These venues offer the latest audio-visual technology and
              uninterrupted 5G enabled connectivity for all kinds of meetings,
              discussions and reviews, for an end-to-end, seamless experience.
            </p>
            <img src="assets/Jio-world-convention-center-fe-develop/assets/host.png" alt="host" class="mob_accordian_img" />
            <a href="#"><button class="main_button other_btn">ENQUIRE NOW</button></a>
          </div>
        </div>
        <div class="accordian">
          <div class="qtn" onclick="handleAccordian(this, 'arrow_2', 'assets/Jio-world-convention-center-fe-develop/assets/workshop.png')">
            <p class="qtn_title">Workshops & Roadshows</p>
            <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/arrow.png" alt="arrow" class="arrow" id="arrow_2" />
          </div>
          <div class="ans">
            <p>
              These venues offer the latest audio-visual technology and
              uninterrupted 5G enabled connectivity for all kinds of meetings,
              discussions and reviews, for an end-to-end, seamless experience.
            </p>
            <img src="assets/Jio-world-convention-center-fe-develop/assets/workshop.png" alt="host" class="mob_accordian_img" />
            <a href="#"><button class="main_button other_btn">ENQUIRE NOW</button></a>
          </div>
        </div>
        <div class="accordian">
          <div class="qtn" onclick="handleAccordian(this, 'arrow_3', 'assets/Jio-world-convention-center-fe-develop/assets/event.png')">
            <p class="qtn_title">Social Events</p>
            <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/arrow.png" alt="arrow" class="arrow" id="arrow_3" />
          </div>
          <div class="ans">
            <p>
              These venues offer the latest audio-visual technology and
              uninterrupted 5G enabled connectivity for all kinds of meetings,
              discussions and reviews, for an end-to-end, seamless experience.
            </p>
            <img src="assets/Jio-world-convention-center-fe-develop/assets/event.png" alt="host" class="mob_accordian_img" />
            <a href="#"><button class="main_button other_btn">ENQUIRE NOW</button></a>
          </div>
        </div>
      </div>

      <div class="pod_image_wrapper host_image_wrapper">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/host.png" alt="host" class="accordian_img" id="accordian_img" />
        <div class="host_border"></div>
      </div>
    </div>
  </section> -->

  <!-- VENUE SHOWCASE -->
  <!-- <section class="venue_showcase_wrapper"> -->
  <!-- <div class="venue_title_wrapper">
      <div class="line"></div>
      <p class="venue_title">Venue Showcase</p>
    </div>

    <div class="showcase_wrapper">
     
      <div class="showcase_main_img_wrapper active_room_image" id="207">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/room207.png" alt="room" class="showcase_img" id="207-rooms" />
        <div class="showcase_room_title" id="207-subname">
          <p>Meeting Room 207- A</p>
        </div>
      </div>

      
      <div class="showcase_main_img_wrapper" id="208">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/room208.png" alt="room" class="showcase_img" id="208-rooms" />
        <div class="showcase_room_title" id="208-subname">
          <p>Meeting Room 208</p>
        </div>
      </div>

      <div class="room_tabs_mainwrapper">
        <div class="room_tabs" id="tabs">
          <p class="room_tab active_room_tab"
            onclick="handleRoom('207', '207-details', this, '207-first_subimage', event)">
            Meeting Room 207
          </p>
          <p class="room_tab" onclick="handleRoom('208', '208-details', this, '208-first_subimage', event)">
            Meeting Room 208
          </p>
        </div>


        <div class="room_details_wrapper active_room_details" id="207-details">
          <div class="room_details_subwrapper active_details" id="room_no_207_A">
            <p class="room_no">Meeting Room 207-A</p>
            <p class="subtitle">
              Designed for excellence, our meeting rooms and business lounges
              set the highest standards of infrastructure, technology and
              service.
            </p>
          </div>

          <div class="room_details_subwrapper" id="room_no_207_B">
            <p class="room_no">Meeting Room 207-B</p>
            <p class="subtitle">
              Designed for excellence, our meeting rooms and business lounges
              set the highest standards of infrastructure, technology and
              service.
            </p>
          </div>

          <div class="room_details_subwrapper" id="room_no_207_C">
            <p class="room_no">Meeting Room 207-C</p>
            <p class="subtitle">
              Designed for excellence, our meeting rooms and business lounges
              set the highest standards of infrastructure, technology and
              service.
            </p>
          </div>

          <div class="rooms_subimages">
            <img src="assets/Jio-world-convention-center-fe-develop/assets/207-a.png" alt="room" class="subimage active_subimage"
              onclick="handleRoomImage('207-rooms', 'assets/Jio-world-convention-center-fe-develop/assets/room207.png', this, 'Meeting Room 207- A', '207-subname', 'room_no_207_A')"
              id="207-first_subimage" />
            <img src="assets/Jio-world-convention-center-fe-develop/assets/207-b.png" alt="room" class="subimage"
              onclick="handleRoomImage('207-rooms', 'assets/Jio-world-convention-center-fe-develop/assets/room208.png', this, 'Meeting Room 207- B', '207-subname', 'room_no_207_B')" />
            <img src="assets/Jio-world-convention-center-fe-develop/assets/207-c.png" alt="room" class="subimage"
              onclick="handleRoomImage('207-rooms', 'assets/Jio-world-convention-center-fe-develop/assets/room207.png', this, 'Meeting Room 207- C', '207-subname', 'room_no_207_C')" />
          </div>
        </div>


        <div class="room_details_wrapper" id="208-details">
          <div class="room_details_subwrapper active_details" id="room_no_208_A">
            <p class="room_no">Meeting Room 208-A</p>
            <p class="subtitle">
              Designed for excellence, our meeting rooms and business lounges
              set the highest standards of infrastructure, technology and
              service.
            </p>
          </div>
          <div class="room_details_subwrapper" id="room_no_208_B">
            <p class="room_no">Meeting Room 208-B</p>
            <p class="subtitle">
              Designed for excellence, our meeting rooms and business lounges
              set the highest standards of infrastructure, technology and
              service.
            </p>
          </div>
          <div class="room_details_subwrapper" id="room_no_208_C">
            <p class="room_no">Meeting Room 208-C</p>
            <p class="subtitle">
              Designed for excellence, our meeting rooms and business lounges
              set the highest standards of infrastructure, technology and
              service.
            </p>
          </div>

          <div class="rooms_subimages">
            <img src="assets/Jio-world-convention-center-fe-develop/assets/208-a.png" alt="room" class="subimage active_subimage"
              onclick="handleRoomImage('208-rooms', 'assets/Jio-world-convention-center-fe-develop/assets/room208.png', this, 'Meeting Room 208', '208-subname', 'room_no_208_A')"
              id="208-first_subimage" />
            <img src="assets/Jio-world-convention-center-fe-develop/assets/208-b.png" alt="room" class="subimage"
              onclick="handleRoomImage('208-rooms', 'assets/Jio-world-convention-center-fe-develop/assets/room207.png', this, 'Meeting Room 208- B', '208-subname', 'room_no_208_B')" />
            <img src="assets/Jio-world-convention-center-fe-develop/assets/208-c.png" alt="room" class="subimage"
              onclick="handleRoomImage('208-rooms', 'assets/Jio-world-convention-center-fe-develop/assets/room208.png', this, 'Meeting Room 208- C', '208-subname', 'room_no_208_C')" />
          </div>
        </div>
      </div>
    </div> -->

  <!-- MOBILE  -->
  <!-- <div class="mob_showcase_wrapper">
      <div class="room_tabs" id="tabs">
        <p class="room_tab active_room_tab" onclick="handleSliderRoom('mob_207_slider', 'mob_207_subname')"
          id="mob_207_subname">
          Meeting Room 207
        </p>
        <p class="room_tab" id="mob_208_subname" onclick="handleSliderRoom('mob_208_slider', 'mob_208_subname')">
          Meeting Room 208
        </p>
      </div>

      <div class="room_tabs_swiper">
        
        <div class="mob_slider show_swiper" id="mob_207_slider">
          <div class="venue_swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="showcase_main_img_wrapper active_room_image">
                  <img src="assets/Jio-world-convention-center-fe-develop/assets/room207.png" alt="room" class="showcase_img" id="207-rooms" />
                  <div class="showcase_room_title" id="207-subname">
                    <p>Meeting Room 207- A</p>
                  </div>
                </div>
                <div class="room_details_subwrapper mob_details_subwrapper">
                  <p class="room_no">Meeting Room 207-A</p>
                  <p class="subtitle">
                    Designed for excellence, our meeting rooms and business
                    lounges set the highest standards of infrastructure,
                    technology and service.
                  </p>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="showcase_main_img_wrapper active_room_image">
                  <img src="assets/Jio-world-convention-center-fe-develop/assets/room208.png" alt="room" class="showcase_img" id="207-rooms" />
                  <div class="showcase_room_title" id="207-subname">
                    <p>Meeting Room 207- B</p>
                  </div>
                </div>

                <div class="room_details_subwrapper mob_details_subwrapper">
                  <p class="room_no">Meeting Room 207-B</p>
                  <p class="subtitle">
                    Designed for excellence, our meeting rooms and business
                    lounges set the highest standards of infrastructure,
                    technology and service.
                  </p>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="showcase_main_img_wrapper active_room_image">
                  <img src="assets/Jio-world-convention-center-fe-develop/assets/room207.png" alt="room" class="showcase_img" id="207-rooms" />
                  <div class="showcase_room_title">
                    <p>Meeting Room 207- C</p>
                  </div>
                </div>
                <div class="room_details_subwrapper mob_details_subwrapper">
                  <p class="room_no">Meeting Room 207-C</p>
                  <p class="subtitle">
                    Designed for excellence, our meeting rooms and business
                    lounges set the highest standards of infrastructure,
                    technology and service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mob_slider" id="mob_208_slider">
          <div class="venue_swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="showcase_main_img_wrapper active_room_image" id="208">
                  <img src="assets/Jio-world-convention-center-fe-develop/assets/room208.png" alt="room" class="showcase_img" id="207-rooms" />
                  <div class="showcase_room_title" id="208-subname">
                    <p>Meeting Room 208- A</p>
                  </div>
                </div>
                <div class="room_details_subwrapper mob_details_subwrapper">
                  <p class="room_no">Meeting Room 208-A</p>
                  <p class="subtitle">
                    Designed for excellence, our meeting rooms and business
                    lounges set the highest standards of infrastructure,
                    technology and service.
                  </p>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="showcase_main_img_wrapper active_room_image" id="207">
                  <img src="assets/Jio-world-convention-center-fe-develop/assets/room207.png" alt="room" class="showcase_img" id="207-rooms" />
                  <div class="showcase_room_title" id="208-subname">
                    <p>Meeting Room 208- B</p>
                  </div>
                </div>
                <div class="room_details_subwrapper mob_details_subwrapper">
                  <p class="room_no">Meeting Room 208-B</p>
                  <p class="subtitle">
                    Designed for excellence, our meeting rooms and business
                    lounges set the highest standards of infrastructure,
                    technology and service.
                  </p>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="showcase_main_img_wrapper active_room_image" id="207">
                  <img src="assets/Jio-world-convention-center-fe-develop/assets/room208.png" alt="room" class="showcase_img" id="207-rooms" />
                  <div class="showcase_room_title" id="208-subname">
                    <p>Meeting Room 208- C</p>
                  </div>
                </div>
                <div class="room_details_subwrapper mob_details_subwrapper">
                  <p class="room_no">Meeting Room 208-C</p>
                  <p class="subtitle">
                    Designed for excellence, our meeting rooms and business
                    lounges set the highest standards of infrastructure,
                    technology and service.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="room_details_subwrapper">
            <p class="room_no">Meeting Room 208</p>
            <p class="subtitle">
              Designed for excellence, our meeting rooms and business lounges
              set the highest standards of infrastructure, technology and
              service.
            </p>
          </div>
        </div>

        <div class="venue_arrows">
          <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/venue_next_arrow.png" alt="arrow" class="venue_arrow venue_swiper_arrow_prev" />
          <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/venue_next_arrow.png" alt="arrow" class="venue_arrow venue_swiper_arrow_next" />
        </div>
      </div>
    </div> -->
  <!-- </section> -->

  <!-- ORGANISERS SPEAK -->
  <!-- <section class="organisers_wrapper">
    <div class="venue_title_wrapper">
      <div class="line"></div>
      <p class="host_title">Organisers Speak</p>
    </div>

    <div class="org_swiper">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="swiper_card">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/swiper_img.png" alt="swiper_img" class="swiper_img" />

              <div class="swiper_details_wrapper">
                <p class="swiper_detail">
                  “Mumbai is an important international trade fair location
                  and as Messe München, we are very pleased that
                  JioWorld Convention Centre is opening. It meets
                  international standards and will give a further boost to
                  Mumbai as a trade fair location.”
                </p>
                <div>
                  <p class="name">- Gerhard Gerritzen</p>
                  <p class="designation">Deputy MD, Messe München </p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper_card">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/swiper_img.png" alt="swiper_img" class="swiper_img" />

              <div class="swiper_details_wrapper">
                <p class="swiper_detail">
                  “Mumbai is an important international trade fair location
                  and as Messe München, we are very pleased that
                  JioWorld Convention Centre is opening. It meets
                  international standards and will give a further boost to
                  Mumbai as a trade fair location.”
                </p>
                <div>
                  <p class="name">- Gerhard Gerritzen</p>
                  <p class="designation">Deputy MD, Messe München </p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper_card">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/swiper_img.png" alt="swiper_img" class="swiper_img" />

              <div class="swiper_details_wrapper">
                <p class="swiper_detail">
                  “Mumbai is an important international trade fair location
                  and as Messe München, we are very pleased that
                  JioWorld Convention Centre is opening. It meets
                  international standards and will give a further boost to
                  Mumbai as a trade fair location.”
                </p>
                <div>
                  <p class="name">- Gerhard Gerritzen</p>
                  <p class="designation">Deputy MD, Messe München </p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper_card">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/swiper_img.png" alt="swiper_img" class="swiper_img" />

              <div class="swiper_details_wrapper">
                <p class="swiper_detail">
                  “Mumbai is an important international trade fair location
                  and as Messe München, we are very pleased that
                  JioWorld Convention Centre is opening. It meets
                  international standards and will give a further boost to
                  Mumbai as a trade fair location.”
                </p>
                <div>
                  <p class="name">- Gerhard Gerritzen</p>
                  <p class="designation">Deputy MD, Messe München </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <div class="swiper_arrows">
          <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/nextArrow.png" alt="nextArrow" class="swiper_arrow swiper_arrow_prev" />
          <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/nextArrow.png" alt="nextArrow" class="swiper_arrow swiper_arrow_next" />
        </div>
      </div>
    </div>
  </section> -->

  <!-- ASK US ANYTHING -->
  <section class="contact_wrapper">
    <div class="venue_title_wrapper">
      <div class="line"></div>
      <p class="contact_title">Book Our Meeting Pods</p>
      <p class="contact_subtitle" style="padding-top: 2rem;">
        Please call us on +91 22 3570 4400, or walk in through Gate 20 for an on-spot booking at the concierge desk.
      </p>
    </div>

    <!-- <div class="contact_subwrapper">
      <div class="contact_labels">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/call.png" alt="call" class="contact_icon" />
        <p>(316) 555-0116</p>
      </div>
      <div class="contact_labels">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/email.png" alt="email" class="contact_icon" />
        <p>debra.holt@example.com</p>
      </div>
    </div> -->

    <!-- <a href="/enquiries?type=ge&brand=cec&space=meeting-pods" target="_blank"><button class="main_button other_btn enquire_btn" style="margin-top: 3rem;">
      BOOK NOW
      </button></a> -->
  </section>

  <!--EXPLORE MORE VENUES -->
  <!-- <section class="explore_wrapper">
    <div class="explore_title_wrapper">
      <div class="line"></div>
      <p class="contact_title">Explore More Venues</p>
      <p class="explore_subtitle">
        Didn’t find what you were looking for? Discover our full range of
        spaces that are future-proofed for large, small, international and
        state events.
      </p>
    </div>

    <div class="explore_card_wrapper">
      <div class="explore_card">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/exhHall.png" alt="hall" class="hall" />

        <div class="explore_card_details">
          <p class="exp_card_title">Exhibition Halls</p>
          <p class="exp_card_subtitle">
            From conventions and trade shows to concerts and indoor sporting
            events, our halls are the perfect backdrop for all kinds of
            physical, virtual and hybrid events.
          </p>

          <div class="hall_sizes_wrapper">
            <div class="hall_size">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/modular.png" alt="modular" class="hall_icon" />

              <div class="main_size">
                <p class="hall_size_title">3</p>
                <p class="hall_size_subtitle">Modular Halls</p>
              </div>
            </div>
            <div class="hall_size">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/area.png" alt="modular" class="hall_icon" />

              <div class="main_size">
                <p class="hall_size_title">15,000 Sq. m.</p>
                <p class="hall_size_subtitle">Total Area</p>
              </div>
            </div>
            <div class="hall_size">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/reception.png" alt="modular" class="hall_icon" />

              <div class="main_size">
                <p class="hall_size_title">16,500 People</p>
                <p class="hall_size_subtitle">Reception Style</p>
              </div>
            </div>
          </div>

          <div class="hall_btns">
            <a href="#"><button class="main_button other_btn">Know More</button></a>

            <a href="#" class="download_wrapper">
              <p>DOWNLOAD FACT SHEET</p>
              <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/download.png" alt="download" class="download" />
            </a>
          </div>
        </div>
      </div>
      <div class="explore_card">
        <img src="assets/Jio-world-convention-center-fe-develop/assets/convHall.png" alt="hall" class="hall" />

        <div class="explore_card_details">
          <p class="exp_card_title">Convention Halls</p>
          <p class="exp_card_subtitle">
            Host your next conference or trade show at these premium spaces
            that are built to the highest standards of technology and design.
            Our Jasmine Halls are powered by JioFiber-enabled 5G connectivity
            for seamless physical, virtual and hybrid experiences.
          </p>

          <div class="hall_sizes_wrapper">
            <div class="hall_size">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/modular.png" alt="modular" class="hall_icon" />

              <div class="main_size">
                <p class="hall_size_title">2</p>
                <p class="hall_size_subtitle">Modular Halls</p>
              </div>
            </div>
            <div class="hall_size">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/area.png" alt="modular" class="hall_icon" />

              <div class="main_size">
                <p class="hall_size_title">10,000 Sq. m.</p>
                <p class="hall_size_subtitle">Total Area</p>
              </div>
            </div>
            <div class="hall_size">
              <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/reception.png" alt="modular" class="hall_icon" />

              <div class="main_size">
                <p class="hall_size_title">10,640 People</p>
                <p class="hall_size_subtitle">Reception Style</p>
              </div>
            </div>
          </div>

          <div class="hall_btns">
            <a href="#"><button class="main_button other_btn">Know More</button></a>

            <a href="#" class="download_wrapper">
              <p>DOWNLOAD FACT SHEET</p>
              <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/download.png" alt="download" class="download" />
            </a>
          </div>
        </div>
      </div>

      <p class="load_more_lable">LOAD MORE</p>
    </div>
  </section> -->

  <!-- MOBILE BOTTOM BUTTONS -->
  <section class="mob_bottom_buttons">
    <div class="home_btn">
      <p>HOME</p>
    </div>
    <div class="home_btn" onclick="handleScrollTop()">
      <img src="assets/Jio-world-convention-center-fe-develop/assets/icons/top_arrow.png" alt="arrow" />
      <p>TOP</p>
    </div>
  </section>




</div>