<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <div class="banner_small black_tint" [attr.style]="('--data-lineColor:' + menuBgcolor + '; --data-titleColor:#FFFFFF') | safe: 'style'">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a *ngIf="page ==='new-das'; else otherLink" routerLink="/das">Dhirubhai Ambani Square</a>
        <ng-template #otherLink><a routerLink="/">Jio World Centre</a></ng-template> / <strong>Frequently Asked
          Questions</strong>
      </div>
    </div>
    <picture *ngIf="page ==='new-das'; else otherBanner">
        <source media="(min-width: 768px)" srcset="./assets/images/dhirubhai-ambani-square-faqs-kma-desktop.jpg">
        <source media="(max-width: 767px)" srcset="./assets/images/dhirubhai-ambani-square-faqs-kma-mobile.jpg">
        <img src="./assets/images/dhirubhai-ambani-square-faqs-kma-desktop.jpg"
            class="img-fluid" alt="Banner">
    </picture>
    <ng-template #otherBanner>
      <source media="(min-width: 768px)" srcset="./assets/images/faqs-kma-desktop.jpg">
        <source media="(max-width: 767px)" srcset="./assets/images/faqs-kma-mobile.jpg">
        <img src="./assets/images/faqs-kma-desktop.jpg"
            class="img-fluid" alt="Banner">
    </ng-template>
    <h1>FAQs</h1>
</div>
  <section class="sub_page_content faq_sidebar">
    <div class="col-md-3 sticky-top faq_slide_bar mobileFAQ"
      [attr.style]="('--data-subpage-bgColor:#F5F2ED; --data-subpage-textColor:#4B4847; --data-subpage-hoverColor:#A67D54; --data-subpage-submenulineColor:#4B4847;') | safe: 'style'">
      <div class="sidebarlist sticky-top">
        <div class="col-sm-12 list-btn "> <a><span class="nav-link-txt">{{mobBrandSelected}}</span> </a> </div>
        <div class="sidebarnav">
          <ul class="flex-column">
            <!-- <li> <a href="javascript:void(0);" class="sidebarnav_link"
              [ngClass]="{'active': activeIndex == 0}" [attr.style]="('--data-subpage-activeColor:' + menuBgcolor ) | safe: 'style'" (click)="callFaqCTEvent('pri', 'Jio World
              Centre'); getBrandData(0, true)"><span class="nav-link-txt">Jio World
                CENTRE</span></a> </li> -->
            <li> <a href="javascript:void(0);" class="sidebarnav_link" [ngClass]="{'active': activeIndex == 3}" [attr.style]="('--data-subpage-activeColor:' + menuBgcolor ) | safe: 'style'"
                (click)="callFaqCTEvent('pri', 'Dhirubhai Ambani Square'); getBrandData(3, true)"
               ><span class="nav-link-txt">Dhirubhai Ambani Square</span></a>
            </li>
            <ng-container *ngIf="page !=='new-das'">
              <li> <a href="javascript:void(0);"class="sidebarnav_link" [ngClass]="{'active': activeIndex == 1}" [attr.style]="('--data-subpage-activeColor:' + menuBgcolor ) | safe: 'style'"
                (click)="callFaqCTEvent('pri', 'Jio World Convention Centre'); getBrandData(1, true)" >
                <span class="nav-link-txt">Jio World Convention Centre​</span></a></li>
            <li> <a href="javascript:void(0);"class="sidebarnav_link" [ngClass]="{'active': activeIndex == 2}" [attr.style]="('--data-subpage-activeColor:' + menuBgcolor ) | safe: 'style'"
              (click)="callFaqCTEvent('pri', 'Jio World Garden​'); getBrandData(2, true)" >
              <span class="nav-link-txt">Jio World Garden​</span></a></li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="faqs_top_links sticky-top">
      <div class="container">
        <div class="row sidebarlist">
          <div class="col-sm-12 list-btn "> <a href="javascript:void(0);"><span
                class="nav-link-txt">{{selectedMobileTitle ? selectedMobileTitle :
                generalCategoryData?.ComponentHeaderTitle?.data}}</span>
            </a> </div>
          <ul class="navbar-nav sidebarnav">
            <li class="nav-item" *ngIf="generalCategoryData?.HideComponent?.data ==='false'"><a
                class="sidebarnav_link faqtoplink" routerLink="./"
                [attr.fragment]="getFragmentId(generalCategoryData?.ComponentHeaderTitle?.data)"
                [fragment]="getFragmentId(generalCategoryData?.ComponentHeaderTitle?.data)"
                (click)="callFaqCTEvent('sec', generalCategoryData?.ComponentHeaderTitle?.data); setMobHeaderTitle(generalCategoryData?.ComponentHeaderTitle?.data)">{{generalCategoryData?.ComponentHeaderTitle?.data}}</a>
            </li>
            <li class="nav-item" *ngIf="bookingCategoryData?.HideComponent?.data ==='false'"><a
                class="sidebarnav_link faqtoplink" routerLink="./"
                [attr.fragment]="getFragmentId(bookingCategoryData?.ComponentHeaderTitle?.data)"
                [fragment]="getFragmentId(bookingCategoryData?.ComponentHeaderTitle?.data)"
                (click)="callFaqCTEvent('sec', bookingCategoryData?.ComponentHeaderTitle?.data); setMobHeaderTitle(bookingCategoryData?.ComponentHeaderTitle?.data)">{{bookingCategoryData?.ComponentHeaderTitle?.data}}</a>
            </li>
            <li class="nav-item" *ngIf="aasCategoryData?.HideComponent?.data ==='false'"><a
                class="sidebarnav_link faqtoplink" routerLink="./"
                [attr.fragment]="getFragmentId(aasCategoryData?.ComponentHeaderTitle?.data)"
                [fragment]="getFragmentId(aasCategoryData?.ComponentHeaderTitle?.data)"
                (click)="callFaqCTEvent('sec', aasCategoryData?.ComponentHeaderTitle?.data); setMobHeaderTitle(aasCategoryData?.ComponentHeaderTitle?.data)">{{aasCategoryData?.ComponentHeaderTitle?.data}}</a>
            </li>
            <li class="nav-item" *ngIf="planAVisitData?.HideComponent?.data ==='false'"><a
                class="sidebarnav_link faqtoplink" routerLink="./"
                [attr.fragment]="getFragmentId(planAVisitData?.ComponentHeaderTitle?.data)"
                [fragment]="getFragmentId(planAVisitData?.ComponentHeaderTitle?.data)"
                (click)="callFaqCTEvent('sec', planAVisitData?.ComponentHeaderTitle?.data); setMobHeaderTitle(planAVisitData?.ComponentHeaderTitle?.data)">{{planAVisitData?.ComponentHeaderTitle?.data}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container leftsidenav faqsidenav">
      <div class="row">
        <div class="col-md-3 sticky-top faq_slide_bar desktopFAQ"
           [attr.style]="('--data-subpage-bgColor:#F5F2ED; --data-subpage-textColor:#4B4847; --data-subpage-hoverColor:#A67D54; --data-subpage-submenulineColor:#4B4847;') | safe: 'style'">
          <div class="sidebarlist sticky-top">
            <div class="col-sm-12 list-btn "> <a><span class="nav-link-txt">{{mobBrandSelected}}</span> </a> </div>
            <div class="sidebarnav">
              <ul class="flex-column">
                <!-- <li> <a href="javascript:void(0);" [attr.style]="('--data-subpage-activeColor:' + menuBgcolor ) | safe: 'style'" (click)="getBrandData(0, true)" class="sidebarnav_link"
                    [ngClass]="{'active': activeIndex == 0}"><span class="nav-link-txt">Jio World
                      CENTRE</span></a> </li> -->
                <li> <a href="javascript:void(0);"
                    [attr.style]="('--data-subpage-activeColor:' + menuBgcolor ) | safe: 'style'"
                    (click)="callFaqCTEvent('pri', 'Dhirubhai Ambani Square'); getBrandData(3, true)"
                    class="sidebarnav_link" [ngClass]="{'active': activeIndex == 3}">
                    <span class="nav-link-txt">Dhirubhai Ambani Square</span></a>
                </li>
                <ng-container *ngIf="page !=='new-das'">
                  <li> <a href="javascript:void(0);" [attr.style]="('--data-subpage-activeColor:' + menuBgcolor ) | safe: 'style'"
                      (click)="callFaqCTEvent('pri', 'Jio World Convention Centre'); getBrandData(1, true)" class="sidebarnav_link" [ngClass]="{'active': activeIndex == 1}">
                      <span class="nav-link-txt">Jio World Convention Centre​</span></a></li>
                  <li> <a href="javascript:void(0);" [attr.style]="('--data-subpage-activeColor:' + menuBgcolor ) | safe: 'style'"
                    (click)="callFaqCTEvent('pri', 'Jio World Garden​'); getBrandData(2, true)" class="sidebarnav_link" [ngClass]="{'active': activeIndex == 2}">
                    <span class="nav-link-txt">Jio World Garden​</span></a></li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-9 content">
          <div data-spy="scroll" data-target="#faqs-top-data" data-offset="0">
            <div [id]="getFragmentId(generalCategoryData?.ComponentHeaderTitle?.data)"
              *ngIf="generalCategoryData?.HideComponent?.data ==='false'">
              <div class="faqs_wrapper faq_page">
                <div class="container">
                  <div class="section_heading text_bold section_heading--title">
                    <h2>{{generalCategoryData?.ComponentHeaderTitle?.data}}</h2>
                  </div>
                  <div id="accordion" class="cus_accordion">
                    <div class="card" *ngFor="let item of generalCategoryData?.cards; let i = index">
                      <div class="card-header" id="{{'heading'+(i+1)}}">
                        <h5 class="mb-0">
                          <button class="btn btn-link content2"
                            (click)="callFaqCTEvent('cont', item?.Question?.data, generalCategoryData?.ComponentHeaderTitle?.data);"
                            data-toggle="collapse" [attr.data-target]="'#faq_general_collapse'+(i+1)"
                            [attr.aria-expanded]="checkExpandFlag(i)"
                            [attr.aria-controls]="'faq_general_collapse'+(i+1)">
                            {{item?.Question?.data}}
                            <span class="icon-arrow-down"></span>
                          </button>
                        </h5>
                      </div>
                      <div id="{{'faq_general_collapse'+(i+1)}}" class="collapse" [ngClass]="{'show': i == 0}"
                        [attr.aria-labelledby]="'heading'+(i+1)" data-parent="#accordion">
                        <div class="card-body">
                          <div class="card-body_copy">
                            <p [innerHTML]="item?.Answer?.data" class="content3">
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [innerHTML]="jsonLDforGeneral"></div>
            </div>

            <div [id]="getFragmentId(bookingCategoryData?.ComponentHeaderTitle?.data)"
              *ngIf="bookingCategoryData?.HideComponent?.data ==='false'">
              <div class="faqs_wrapper faq_page">
                <div class="container">
                  <div class="section_heading text_bold section_heading--title">
                    <h2>{{bookingCategoryData?.ComponentHeaderTitle?.data}}</h2>
                  </div>
                  <div id="accordion2" class="cus_accordion">
                    <div class="card" *ngFor="let item of bookingCategoryData?.cards; let i = index">
                      <div class="card-header" id="{{'heading'+(i+1)}}">
                        <h5 class="mb-0">
                          <button class="btn btn-link content2"
                            (click)="callFaqCTEvent('cont', item?.Question?.data, bookingCategoryData?.ComponentHeaderTitle?.data);"
                            data-toggle="collapse" [attr.data-target]="'#faq_booking_collapse'+(i+1)"
                            [attr.aria-expanded]="checkExpandFlag(i)"
                            [attr.aria-controls]="'faq_booking_collapse'+(i+1)">
                            {{item?.Question?.data}}
                            <span class="icon-arrow-down"></span>
                          </button>
                        </h5>
                      </div>

                      <div id="{{'faq_booking_collapse'+(i+1)}}" class="collapse" [ngClass]="{'show': i == 0}"
                        [attr.aria-labelledby]="'heading'+(i+1)" data-parent="#accordion2">
                        <div class="card-body">
                          <div class="card-body_copy">
                            <p [innerHTML]="item?.Answer?.data" class="content3"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
              <div [innerHTML]="jsonLDforBooking"></div>
            </div>


            <div [id]="getFragmentId(aasCategoryData?.ComponentHeaderTitle?.data)"
              *ngIf="aasCategoryData?.HideComponent?.data ==='false'">
              <div class="faqs_wrapper faq_page">
                <div class="container">
                  <div class="section_heading text_bold section_heading--title">
                    <h2>{{aasCategoryData?.ComponentHeaderTitle?.data}}</h2>
                  </div>
                  <div id="accordion3" class="cus_accordion">
                    <div class="card" *ngFor="let item of aasCategoryData?.cards; let i = index">
                      <div class="card-header" id="{{'heading'+(i+1)}}">
                        <h5 class="mb-0">
                          <button class="btn btn-link content2"
                            (click)="callFaqCTEvent('cont', item?.Question?.data, aasCategoryData?.ComponentHeaderTitle?.data);"
                            data-toggle="collapse" [attr.data-target]="'#faq_serverices_collapse'+(i+1)"
                            [attr.aria-expanded]="checkExpandFlag(i)"
                            [attr.aria-controls]="'faq_serverices_collapse'+(i+1)">
                            {{item?.Question?.data}}
                            <span class="icon-arrow-down"></span>
                          </button>
                        </h5>
                      </div>

                      <div id="{{'faq_serverices_collapse'+(i+1)}}" [ngClass]="{'show': i == 0}" class="collapse"
                        [attr.aria-labelledby]="'heading'+(i+1)" data-parent="#accordion3">
                        <div class="card-body">
                          <div class="card-body_copy">
                            <p [innerHTML]="item?.Answer?.data" class="content3">
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [innerHTML]="jsonLDforAas"></div>
            </div>

            <div [id]="getFragmentId(planAVisitData?.ComponentHeaderTitle?.data)"
              *ngIf="planAVisitData?.HideComponent?.data ==='false'">
              <div class="faqs_wrapper faq_page">
                <div class="container">
                  <div class="section_heading text_bold section_heading--title">
                    <h2>{{planAVisitData?.ComponentHeaderTitle?.data}}</h2>
                  </div>
                  <div id="accordion4" class="cus_accordion">
                    <div class="card" *ngFor="let item of planAVisitData?.cards; let i=index">
                      <div class="card-header" id="{{'heading'+(i+1)}}">
                        <h5 class="mb-0">
                          <button class="btn btn-link content2"
                            (click)="callFaqCTEvent('cont', item?.Question?.data, planAVisitData?.ComponentHeaderTitle?.data);"
                            data-toggle="collapse" [attr.data-target]="'#faq_plan_collapse'+(i+1)"
                            [attr.aria-expanded]="checkExpandFlag(i)" [attr.aria-controls]="'faq_plan_collapse'+(i+1)">
                            {{item?.Question?.data}}
                            <span class="icon-arrow-down"></span>
                          </button>
                        </h5>
                      </div>

                      <div id="{{'faq_plan_collapse'+(i+1)}}" class="collapse" [ngClass]="{'show': i == 0}"
                        [attr.aria-labelledby]="'heading'+(i+1)" data-parent="#accordion4">
                        <div class="card-body">
                          <div class="card-body_copy">
                            <p [innerHTML]="item?.Answer?.data" class="content3">
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [innerHTML]="jsonLDforPlanAVisit"></div>
            </div>
            

          </div>
        </div>
      </div>
    </div>
  </section>





  <!-- <section class="jwc_404_head" data-aos="fade-up" *ngIf="contactFaqData">
    <div class="container">
      <div class="section_heading text-center fullwidth" style="--data-lineColor:#C69E67">
        <div class="">
          <h2 class="section_heading--title">{{contactFaqData?.ComponentTitle?.data}}</h2>
          <p class="section_heading--text"> {{contactFaqData?.ComponentShortText?.data}}
          </p>
        </div>
      </div>
      <div class="jwc_404_links">
       // <a href="javascript:void(0);" class="cta-prim color-gold"><span class="cta-prim-txt">Support</span></a>
        <a (click)="_sharedService.checkCTALink($event, contactFaqData?.ContactUsCTALink?.data, contactFaqData?.CTAWindowPreference?.data)"
          class="cta-prim color-gold" *ngIf="contactFaqData?.HideContactUsCTA?.data == 'false'"
          href="{{contactFaqData?.ContactUsCTALink?.data}}">
          <span class="cta-prim-txt">{{contactFaqData?.CTAText?.data}}</span>
        </a>
      </div>
    </div>
  </section> -->

  <!-- <section class="more_centre_wrapper plr-0" style="--data-more-centre-bgColor1:#ffffff;--data-more-centre-bgColor2: transparent;--data-more-centre-titleColor:#4B4847;
      --data-more_centre_txt_bgColor:#FFFFFF;">
    <div class="container">
      <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#A67D54">
        <div class="">
          <h2 class="section_heading--title">More At Jio World Centre</h2>
        </div>
      </div>
      <div class="more_centre_slider jwc_swiper_normal" data-interval="8" data-space="24" data-slides="3"
        data-mob="false" data-ipad-slides="2.2" data-ipad-space="20" data-mobile-slides="1.15" data-mobile-space="20">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                  <span class="more_centre_slider_each_copy--category">Food Festival</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0);" class="cta-prim color-blue icon icon-ticket"><span
                        class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/2.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                  <span class="more_centre_slider_each_copy--category">Performing Arts</span>
                  <h4 class="more_centre_slider_each_copy--title">Kalasri - Bharatnatyam</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0);" class="cta-prim color-blue icon icon-ticket"><span
                        class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/3.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                  <span class="more_centre_slider_each_copy--category">Visual Arts</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0);" class="cta-prim color-blue icon icon-ticket"><span
                        class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>


    </div>
  </section> -->
</main>
<!-- <app-footer [colorList]="footerFaqsColorList"></app-footer> -->
<div class="overlay"></div>