import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../../../shared/services/shared.service';
import { environment } from '../../../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-cec-venue-details',
  templateUrl: './cec-venue-details.component.html',
  styleUrls: ['./cec-venue-details.component.css']
})
export class CecVenueDetailsComponent implements OnInit {
  subscription;
  landingBgImage: boolean;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  cecVenueDetailsBannerData: any;
  cecVenueDetailsHallsData: any;
  cecVenueDetailsFloorPlansData: any;
  cecVenueDetailsHighlightsInfoData: any;
  cecVenueDetailsEventsData: any;
  cecVenueDetailsShowcaseData: any;
  cecVenueDetailsAskUsData: any;
  cecVenueDetailsExploreData: any;
  cecVenueDetailsOrgSpeakData: any;
  cecVenueDetailsPlanEventData: any;
  cecVenueDetailsHeaderList;
  pdfName: string;
  cecVenueDetailsKeyArray;
  breadcrumbName: string;
  venueDetailsId: any;
  planEventId: any;
  testimonialsId: any;
  cecVenueDetailsMobHeaderList;
  fragment;
  cecVenueDetailsColorList = {
    footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', headerDataHoverColor: "#1E478E", dataExperiencesTitleColor: '#4B4847', dataMobileLineBg: 'transparent', dataTitleColor: '#4B4847', dataLineColor: '#1E478E', dataTabTxtColor: '#4B4847', dataTabTxtHighlightColor: '#6CC24A', dataVenuesTitleColor: '#4B4847', dataVenuesLineColor: '#004EA8', dataSpeakerItemColor: '#004EA8', organiserTitleColor: '#4B4847', organiserLineColor: '#004EA8', dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#004EA8', dataPlanBgColor: '#F5F2ED', dataNewsletter2LineColor: '#004EA8', dataPlanLineColor: '#004EA8'
  };
  meetingRoomPackageData;
  fullMeetingRoomPackageData;
  smallMeetingRoomPackageData;
  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router: Router,
    private titleService: Title) {
      let flag = true;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const fragment: string = this.activatedRoute.snapshot.fragment;
        if (!fragment) {jQuery('.loader').show(); flag = true;}
        if(flag) {
          this.cecVenueDetailsOrgSpeakData ='';
          this.cecVenueDetailsBannerData = '';
          this.getKeys();
          this.cecVenueDetailsMobHeaderList = (window.innerWidth > 991)?["Floor Plan", "Plan Your Event", 'Events We Host', "Venue Showcase", "Organisers Speak"] :
           [this.breadcrumbName, "Floor Plan", "Event Services", "Plan", 'Events We Host', "Venue Showcase", "Testimonials", "Contact", "More Venues"]
          this.planEventId = (window.innerWidth > 991) ? 'plan-your-event' :  'plan' ;
          this.testimonialsId = (window.innerWidth > 991) ? "organisers-speak" :  'testimonials' ;
          this.venueDetailsId = this.breadcrumbName.replace(/\s/g, '-').toLowerCase();
          this.getCecVenueDetailsPageContent();
        }
        flag=false;
      }
    });
  }

  ngOnInit() {
  }
  getKeys() {
    switch (this.activatedRoute.snapshot.paramMap.get('room')) {
      case 'convention-halls':
        this.pdfName = 'jio-world-convention-centre-convention-halls-factsheet';
        this.breadcrumbName = 'Convention Halls';
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'Convention Halls Details';
        return this.cecVenueDetailsKeyArray = [
          'CEC-VENUE-DETAILS-HERO-BANNER-CONVENTION-HALLS',
          'CEC-VENUE-DETAILS-CONVENTION-HALLS',
          'CEC-VENUE-DETAILS-HIGHLIGHTS-INFOGRAPHICS',
          'CEC-VENUE-DETAILS-SHOWCASE-CONVENTION-HALLS',
          'CEC-VENUE-DETAILS-ORGANISERS-SPEAK',
          'CEC-VENUE-DETAILS-ASK-US-ANYTHING',
          'CEC-VENUE-DETAILS-FLOOR-PLANS-CONVENTION-HALLS',
          'CEC-VENUE-DETAILS-EVENTS-AT-THE-CONVENTION-HALLS',
          'CEC-VENUE-DETAILS-PLAN-YOUR-EVENT',
          60723, //Exhibition Halls
          60802, //Banquet Halls
          '60889',
        ];
      case 'banquet-halls':
      case 'ballroom':
        this.pdfName = 'jio-world-convention-centre-ballroom-factsheet';
        this.breadcrumbName = 'Ballroom';
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'Banquet Halls Details';
        return this.cecVenueDetailsKeyArray = [
          'CEC-VENUE-DETAILS-HERO-BANNER-BANQUET-HALLS',
          'CEC-VENUE-DETAILS-BANQUET-HALLS',
          'CEC-VENUE-DETAILS-HIGHLIGHTS-INFOGRAPHICS',
          'CEC-VENUE-DETAILS-SHOWCASE-BANQUET-HALLS',
          'CEC-VENUE-DETAILS-ORGANISERS-SPEAK',
          'CEC-VENUE-DETAILS-ASK-US-ANYTHING',
          'CEC-VENUE-DETAILS-FLOOR-PLANS-BANQUET-HALLS',
          'CEC-VENUE-DETAILS-EVENTS-AT-THE-BANQUET-HALLS',
          'CEC-VENUE-DETAILS-PLAN-YOUR-EVENT',
          60723, //Exhibition Halls
          '60764',
          '60889'

        ];
      case 'meeting-rooms':
        this.pdfName = 'jio-world-convention-centre-meeting-rooms-factsheet';
        this.breadcrumbName = 'Meeting Rooms';
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'Meeting Rooms Details';
        return this.cecVenueDetailsKeyArray = [
          'CEC-VENUE-DETAILS-HERO-BANNER-MEETING-ROOMS',
          'CEC-VENUE-DETAILS-MEETING-ROOMS',
          'CEC-VENUE-DETAILS-HIGHLIGHTS-INFOGRAPHICS',
          'CEC-VENUE-DETAILS-SHOWCASE-MEETING-ROOMS',
          'CEC-VENUE-DETAILS-ORGANISERS-SPEAK',
          'CEC-VENUE-DETAILS-ASK-US-ANYTHING',
          'CEC-VENUE-DETAILS-FLOOR-PLANS-MEETING-ROOMS',
          'CEC-VENUE-DETAILS-EVENTS-AT-THE-MEETING-ROOMS',
          'CEC-VENUE-DETAILS-PLAN-YOUR-EVENT',
          60723, //Exhibition Halls
          '60764',
          60802, //Banquet Halls
          'CEC-VENUE-DETAILS-MEETING-ROOM-PACKAGE', // meeting room package
          'CEC-VENUE-DETAILS-FULL-MEETING-ROOM-PACKAGE', // full meeting room package
          'CEC-Venue-Details-Small-Meeting-Room'
        ];
      default:
        this.pdfName = 'jio-world-convention-centre-exhibition-halls-factsheet';
        this.breadcrumbName = 'Exhibition Halls';
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'Exhibition Halls Details';
        return this.cecVenueDetailsKeyArray = [
          'CEC-VENUE-DETAILS-HERO-BANNER',
          'CEC-VENUE-DETAILS-EXHIBITION-HALLS',
          'CEC-VENUE-DETAILS-HIGHLIGHTS-INFOGRAPHICS',
          'CEC-VENUE-DETAILS-SHOWCASE',
          'CEC-VENUE-DETAILS-ORGANISERS-SPEAK',
          'CEC-VENUE-DETAILS-ASK-US-ANYTHING',
          'CEC-VENUE-DETAILS-FLOOR-PLANS-EXHIBITION-HALLS',
          'CEC-VENUE-DETAILS-EVENTS-AT-THE-EXHIBITION-HALLS',
          'CEC-VENUE-DETAILS-PLAN-YOUR-EVENT',
          '60764',
          60802, //Banquet Halls
          '60889'
        ];
    }
  }
  getCecVenueDetailsPageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.cecVenueDetailsKeyArray).subscribe(
      (data: any) => {
        this.cecVenueDetailsBannerData = data[0] ? data[0].data : data[0];
        // if (this.cecVenueDetailsBannerData.BackgroundImageVideo.data === 'image') {
        //   this.landingBgImage = true;
        // }
        this.cecVenueDetailsHallsData = data[1] ? data[1].data : data[1];
        this.cecVenueDetailsHighlightsInfoData = data[2] ? data[2].data : data[2];
        this.cecVenueDetailsShowcaseData = data[3] ? data[3].data : data[3];
        this.cecVenueDetailsOrgSpeakData = data[4] ? data[4].data : data[4];
        this.cecVenueDetailsAskUsData = data[5] ? data[5].data : data[5];
        this.cecVenueDetailsFloorPlansData = data[6] ? data[6].data : data[6];
        this.cecVenueDetailsEventsData = data[7] ? data[7].data : data[7];
        this.cecVenueDetailsPlanEventData = data[8] ? data[8].data : data[8];
        this.cecVenueDetailsExploreData = [
          data[9] ? data[9].data : data[9],
          data[10] ? data[10].data : data[10],
          data[11] ? data[11].data : data[11]
        ];
        this.meetingRoomPackageData =  data[12] ? data[12].data : data[12];
        this.fullMeetingRoomPackageData =  data[13] ? data[13].data : data[13];
        this.smallMeetingRoomPackageData = data[14] ? data[14].data : data[14];
        this._sharedService.initializeCustomJs();
        setTimeout(() => {
          this._sharedService.callAccordianWithSlider();
        }, 800);
        this.cecVenueDetailsHeaderList = this._sharedService.subHeaderItems(this.cecVenueDetailsMobHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
