import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { constants } from '../../../shared/shared.constant';

@Component({
  selector: 'app-general-cec',
  templateUrl: './general-cec.component.html',
  styleUrls: ['./general-cec.component.css']
})
export class GeneralCecComponent implements OnInit, OnDestroy {
  generalCecInquiryForm: FormGroup;
  inquiryType = 'General';
  brand = 'Jio World Convention Centre';
  spaceValue;
  typeOfEvent ;
  countryCode;
  submitted = false;
  errorText = 'Field is required';
  generalCECData;
  generalCECArrData;
  disableBtn;
  @Input() set disableSubmitBtn(value) {
    this.disableBtn = value;
  }
  @Output() inquiryTypeValue = new EventEmitter();
  @Output() brandValue = new EventEmitter();
  @Input() inquiryData;
  @Input() typeOfHall;
  @Input() captchaSiteKey;
  isCaptchaSelected = false;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;  
  apiErrorMsg = "";

  grcToken =  "XXXXXXXXXXX";

  constructor(private formBuilder: FormBuilder, public _sharedService: SharedService, private router: Router) { this._sharedService.inquiryApiErrorMsg.subscribe(
    (data) => {
      if (data['error'].message) {
        this.apiErrorMsg = "Server error. Please try after some time...";
        // this.apiErrorMsg = data['error'].message;
      }
    }
  );}

  ngOnInit() {
    let prefilledData = this._sharedService.getInquiryFormValues();
    this.generalCECData = this.inquiryData.cards[0];
    this.generalCECArrData = this.generalCECData.cec;
    this.generalCecInquiryForm = this.formBuilder.group({
      inquiryType: ['General'],
      brand: ['CEC'],
      spaceValue: this.formBuilder.array([]),
      // spaceValue: [''],
      typeOfEvent: [this._sharedService.configData.default_enquiries_dropdown_value || 'Did not say'],
      nameInput: [(prefilledData.name)?prefilledData.name:'', Validators.required],
      companyInput: [(prefilledData.company)?prefilledData.company:''],
      emailInput: [(prefilledData.email)?prefilledData.email:'', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      countryCode: ['+91'],
      phoneNoInput: [(prefilledData.phoneNo)?prefilledData.phoneNo:'', Validators.minLength(10)],
      messageInput: [(prefilledData.message)?prefilledData.message:'', Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
      // hearAboutUs: this.formBuilder.array([])
      hearAboutUs: [this._sharedService.configData.default_enquiries_dropdown_value || 'Did not say']
    });
    // this.setDropdownValue('spaceValue', this.typeOfHall);
    if(this.typeOfHall) this.onSpaceCheckboxClick(null, true, this.typeOfHall);
    // this.spaceValue = this.typeOfHall;
    this._sharedService.callCustomDropdown();
  }

  setDropdownValue(formControlname, value) {
    this.generalCecInquiryForm.get(formControlname).setValue(value);
  }

  // onHearUsCheckboxClick(event) {
  //   const hearAboutUsArray: FormArray = this.generalCecInquiryForm.get('hearAboutUs') as FormArray;
  //   if (event.target.checked) {
  //       hearAboutUsArray.push(new FormControl(event.target.value));
  //   } else {
  //       let i: number = 0;
  //     hearAboutUsArray.controls.forEach((item: FormControl) => {
  //       if (item.value == event.target.value) {
  //         hearAboutUsArray.removeAt(i);
  //         return;
  //       }
  //       i++;
  //     });
  //   }
  // }

  onSpaceCheckboxClick(eve?, inputEveChecked?, inputEveValue?) {
    let spacesArray: FormArray = this.generalCecInquiryForm.get('spaceValue') as FormArray;
    let checkedVal = (eve ? eve.target.value : inputEveValue)
    let checkedBox = (eve ? eve.target.checked : inputEveChecked);
    if (checkedBox) {
      spacesArray.push(new FormControl(checkedVal));
    } 
    else {
      let i: number = 0;
      spacesArray.controls.forEach((item: FormControl) => {
        if (item.value == eve.target.value || item.value ==  inputEveValue) {
          spacesArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  get generalCecFormControls() { return this.generalCecInquiryForm.controls; }

  sendGcRouteObj(name) {
    this.router.navigate(['/legal'], {
      state: { tabName: name }
    });
    return false;
  }

  generalCecSubmit(form) {
    this.submitted = true;
    // if(!this.isCaptchaSelected) return;
    if (form.invalid || !this.isCaptchaSelected) {
      return;
    }
    this.disableBtn = true;
    let data = form.value;
    data.grcToken = this.grcToken;
    let generalCecEveObj = {
      Enquiry_Type: data.inquiryType,
      Brand_Name: data.brand,
      Event_Space_Exbh: (data.spaceValue.indexOf('Exhibition Halls') >= 0) ? 'Yes' : 'No',
      Event_Space_Conv: (data.spaceValue.indexOf('Convention Halls') >= 0) ? 'Yes' : 'No',
      Event_Space_Meet: (data.spaceValue.indexOf('Meeting Rooms') >= 0) ? 'Yes' : 'No',
      Event_Space_Banq: (data.spaceValue.indexOf('Ballroom') >= 0) ? 'Yes' : 'No',
      Event_Type: data.typeOfEvent,
      Hear_About_Us: data.hearAboutUs,
      Organiser_Name: data.nameInput,
      Organiser_Company_Name:data.companyInput,
      Organiser_Phone: data.countryCode + data.phoneNoInput,
      Organiser_Email: data.emailInput
      // Event_Name: 'Enquiry_Forms'
    };
    let generalCecCTProfile = {
      Organiser_Name: data.nameInput,
      Organiser_Company_Name:data.companyInput,
      Organiser_Phone: data.countryCode + data.phoneNoInput,
      Organiser_Email: data.emailInput
    };
    // console.log("form values: ", data);
    // data.hearAboutUs = [];
    // data.hearAboutUs.push(this.generalCecInquiryForm.get('hearAboutUs').value);
    this._sharedService.submitInquiryForm(data, generalCecEveObj, generalCecCTProfile);
  }

  cecCaptchaRes($event, flag?) {
    if(!$event) return this.isCaptchaSelected = false;
    if(flag) return console.log('Something went wrong when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true;
    this.grcToken = $event;
  }

  ngOnDestroy() {
    let obj = {
      name: (this.generalCecFormControls.nameInput.value)?this.generalCecFormControls.nameInput.value : '',
      email: (this.generalCecFormControls.emailInput.value)?this.generalCecFormControls.emailInput.value : '',
      company: (this.generalCecFormControls.companyInput.value)?this.generalCecFormControls.companyInput.value : '',
      message: (this.generalCecFormControls.messageInput.value)?this.generalCecFormControls.messageInput.value : '',
      phoneNo: (this.generalCecFormControls.phoneNoInput.value)?this.generalCecFormControls.phoneNoInput.value : ''
    }
    this._sharedService.setInquiryFormValues(obj.name, obj.email, obj.company, obj.message, obj.phoneNo);
  }
}
