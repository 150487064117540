import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-meeting-rooms',
  templateUrl: './small-meeting-rooms.component.html',
  styleUrls: ['./small-meeting-rooms.component.scss']
})
export class SmallMeetingRoomsComponent {

  scrolltoContent(){
    let targetElement = document.querySelector('.duration_wrapper')
  if (targetElement) {
    // Use scrollIntoView to scroll to the target element
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
  }
}
