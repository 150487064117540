import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { RecaptchaModule } from "ng-recaptcha";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from './app.component';
import { LandingPageComponent } from './containers/landing-page/landing-page.component';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { ConventionCenterLandingComponent } from './containers/convention-center-landing/convention-center-landing.component';
import { JioworldNitaAmbaniComponent } from './containers/jioworld-nita-ambani/jioworld-nita-ambani.component';
import { ExperiencesSectionComponent } from './views/experiences-section/experiences-section.component';
import { HighlightsSectionComponent } from './containers/landing-page/highlights-section/highlights-section.component';
import { NewsSectionComponent } from './views/news-section/news-section.component';
import { BenefitsPrivilegesSectionComponent } from './views/benefits-privileges-section/benefits-privileges-section.component';
import { SubscribeSectionComponent } from './views/subscribe-section/subscribe-section.component';
import { JwcMomentsSectionComponent } from './views/jwc-moments-section/jwc-moments-section.component';
import { ThankYouPageComponent } from './views/thank-you-page/thank-you-page.component';
import { VenuesWrapperComponent } from './containers/convention-center-landing/venues-wrapper/venues-wrapper.component';
import { GetInTouchComponent } from './views/get-in-touch/get-in-touch.component';
import { MapComponent } from './views/map/map.component';
import { DaSquareComponent } from './containers/jioworld-nita-ambani/da-square/da-square.component';
import { NaccPlanItineraryComponent } from './containers/jioworld-nita-ambani/nacc-plan-itinerary/nacc-plan-itinerary.component';
import { GettingHereComponent } from './views/getting-here/getting-here.component';
import { Subscribe2Component } from './views/subscribe2-section/subscribe2-section.component';
import { FaqSectionComponent } from './views/faq-section/faq-section.component';
import { ArtStoreSectionComponent } from './containers/jioworld-nita-ambani/art-store-section/art-store-section.component';
import { BannerComponent } from './views/banner/banner.component';
import { GardenPageComponent } from './containers/garden-page/garden-page.component';
import { OrganisersSpeakComponent } from './views/organisers-speak/organisers-speak.component';
import { InfographicsSectionComponent } from './views/infographics-section/infographics-section.component';
import { CecWalkthroughComponent } from './containers/convention-center-landing/cec-walkthrough/cec-walkthrough.component';
import { GardenVenueDetailsComponent } from './containers/garden-page/garden-venue-details/garden-venue-details.component';
import { PressAndMediaComponent } from './containers/press-and-media/press-and-media.component';
import { FooterFaqsComponent } from './containers/footer-faqs/footer-faqs.component';
import { LegalPolicyComponent } from './views/footer/legal-policy/legal-policy.component';
import { GardenArchivesComponent } from './containers/garden-page/garden-archives/garden-archives.component';
import { NewsroomComponent } from './containers/newsroom/newsroom.component';
import { DownloadableTechSpecsComponent } from './views/downloadable-tech-specs/downloadable-tech-specs.component';
import { Covid19PageComponent } from './containers/covid19-page/covid19-page.component';
import { CecVenueListingComponent } from './containers/convention-center-landing/cec-venue-listing/cec-venue-listing.component';
import { CecVenueDetailsComponent } from './containers/convention-center-landing/cec-venue-listing/cec-venue-details/cec-venue-details.component';
import { ContactUsComponent } from './containers/contact-us/contact-us.component';
import { CookiePolicyComponent } from './containers/cookie-policy/cookie-policy.component';
import { GardenFacilitiesComponent } from './containers/garden-page/garden-venue-details/garden-facilities/garden-facilities.component';
import { AddressCardComponent } from './containers/contact-us/address-card/address-card.component';
import { SubHeaderComponent } from './views/sub-header/sub-header.component';
import { P500Component } from './shared/components/error/500.component';
import { P404Component } from './shared/components/error/404.component';
import { InquiryHomeComponent } from './views/inquiry-home/inquiry-home.component';
import { GeneralCecComponent } from './views/inquiry-home/general-cec/general-cec.component';
import { GeneralGardenComponent } from './views/inquiry-home/general-garden/general-garden.component';
import { AppInitService } from './shared/services/app-init.service';
import { GetDirectionPageComponent } from './containers/get-direction-page/get-direction-page.component';
import { CecVenueDetailsHallsComponent } from './containers/convention-center-landing/cec-venue-listing/cec-venue-details/cec-venue-details-halls/cec-venue-details-halls.component';
import { EventServicesComponent } from './views/event-services/event-services.component';
import { VenueDetailsShowcaseComponent } from './views/venue-details-showcase/venue-details-showcase.component';
import { CecVenueDetailsFloorPlanComponent } from './containers/convention-center-landing/cec-venue-listing/cec-venue-details/cec-venue-details-floor-plan/cec-venue-details-floor-plan.component';
import { PlanBigEventComponent } from './views/plan-big-event/plan-big-event.component';
import { ExploreVenuesWrapperComponent } from './containers/convention-center-landing/cec-venue-listing/explore-venues-wrapper/explore-venues-wrapper.component';
import { GeneralJwcComponent } from './views/inquiry-home/general-jwc/general-jwc.component';
import { ListFilterPipe } from '../app/shared/pipes/list-filter.pipe';
import { DateSuffixPipe } from './shared/pipes/date-suffix.pipe';
import { InquiryThankYouComponent } from './views/inquiry-home/inquiry-thank-you/inquiry-thank-you.component';
import { VenueHireCecComponent } from './views/inquiry-home/venue-hire-cec/venue-hire-cec.component';
import { VenueHireGardenComponent } from './views/inquiry-home/venue-hire-garden/venue-hire-garden.component';
import { Covid2SectionComponent } from './views/covid2-section/covid2-section.component';
import { CulturalCentrePageComponent } from './containers/cultural-centre-page/cultural-centre-page.component';
import { CecServicePageComponent } from './containers/convention-center-landing/cec-service-page/cec-service-page.component';
import { AboutUsComponent } from './containers/about-us/about-us.component';
import { GardenSafetyComponent } from './containers/garden-page/garden-safety/garden-safety.component';
import { UpgradeBrowserComponent } from './containers/upgrade-browser/upgrade-browser.component';
import { InquiryCareersComponent } from './views/inquiry-home/inquiry-careers/inquiry-careers.component';
import { StickyMenuComponent } from './views/sticky-menu/sticky-menu.component';
import { LoginComponent } from './views/header/login/login.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { RegistrationComponent } from './views/header/login/registration/registration.component';
import { OurWorldComponent } from './views/our-world/our-world.component';
import { SafetyCardComponent } from './containers/covid19-page/safety-card/safety-card.component';
import { ExperiencesGardenSliderComponent } from './views/experiences-garden-slider/experiences-garden-slider.component';
import { CampaignChatbotComponent } from './containers/campaign-chatbot/campaign-chatbot.component';
import { VideoPopupComponent } from './views/video-popup/video-popup.component';
import { LegalPolicyTestComponent } from './views/footer/legal-policy-test/legal-policy-test.component';
import { DasComponent } from './containers/das/das.component';
import { WebsiteNotificationComponent } from './views/website-notification/website-notification.component';
import { DasHeaderComponent } from './views/das-live-views/das-header/das-header.component';
import { DasFooterComponent } from './views/das-live-views/das-footer/das-footer.component'
import { DatePipe } from '@angular/common';
import { NewsArticleComponent } from './views/news-article/news-article.component';
import { CecEventDetailsComponent } from './containers/convention-center-landing/cec-event-details/cec-event-details.component';
import { GuestGuideComponent } from './containers/convention-center-landing/guest-guide/guest-guide.component';
import { SignInComponent } from './views/header/sign-in/sign-in.component';
import { SignUpComponent } from './views/header/sign-up/sign-up.component';
import { LoyaltyProgramComponent } from './views/header/loyalty-program/loyalty-program.component';
import { LogisticsCecComponent } from './views/inquiry-home/logistics-cec/logistics-cec.component';
import { Instagram2SectionComponent } from './views/instagram2-section/instagram2-section.component';
import { LogisticsServicesComponent } from './containers/exhibitor-logistics/logistics-services/logistics-services.component';
import { LogisticsRedirectComponent } from './containers/exhibitor-logistics/logistics-redirect/logistics-redirect.component';
import { BenefitsWrapperComponent } from './views/benefits-wrapper/benefits-wrapper.component';
import { BackToWrapperComponent } from './views/back-to-wrapper/back-to-wrapper.component';
import { BannerNewComponent } from './views/banner-new/banner-new.component';
import { JioworldNitaAmbaniCCComponent } from './containers/jioworld-nita-ambani-cc/jioworld-nita-ambani-cc.component';
import { CareersComponent } from './containers/careers/careers.component';
import { DasNotificationsComponent } from './views/das-notifications/das-notifications.component';
//import { EditNotificationsComponent } from './views/das-notifications/components/edit-notifications/edit-notifications.component';
import { NotificationComponent } from './views/header/notification/notification.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CecEventComponent } from './containers/convention-center-landing/cec-event/cec-event.component';
import { CecWeddingEventComponent } from './containers/convention-center-landing/cec-wedding-event/cec-wedding-event.component';
import { CecVenueDetailsMeetingPackageComponent } from './containers/convention-center-landing/cec-venue-listing/cec-venue-details/cec-venue-details-meeting-package/cec-venue-details-meeting-package.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { CecWeddingDetailsComponent } from './containers/convention-center-landing/cec-wedding-event/cec-wedding-details/cec-wedding-details.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HeaderComponent,
    FooterComponent,
    ConventionCenterLandingComponent,
    JioworldNitaAmbaniComponent, 
    ExperiencesSectionComponent,
    HighlightsSectionComponent,
    NewsSectionComponent,
    BenefitsPrivilegesSectionComponent,
    SubscribeSectionComponent,
    JwcMomentsSectionComponent,
    ThankYouPageComponent,
    VenuesWrapperComponent,
    GetInTouchComponent,
    MapComponent,
    DaSquareComponent,
    NaccPlanItineraryComponent,
    GettingHereComponent,
    Subscribe2Component,
    FaqSectionComponent,
    ArtStoreSectionComponent,
    BannerComponent,
    GardenPageComponent,
    OrganisersSpeakComponent,
    InfographicsSectionComponent,
    CecWalkthroughComponent,
    GardenVenueDetailsComponent,
    PressAndMediaComponent,
    FooterFaqsComponent,
    LegalPolicyComponent,
    GardenArchivesComponent,
    NewsroomComponent,
    DownloadableTechSpecsComponent,
    Covid19PageComponent,
    CecVenueListingComponent,
    CecVenueDetailsComponent,
    ContactUsComponent,
    CookiePolicyComponent,
    GardenFacilitiesComponent,
    AddressCardComponent,
    SubHeaderComponent,
    P500Component,
    P404Component,
    InquiryHomeComponent,
    GeneralCecComponent,
    GeneralGardenComponent,
    GetDirectionPageComponent,
    CecVenueDetailsHallsComponent,
    EventServicesComponent,
    VenueDetailsShowcaseComponent,
    CecVenueDetailsFloorPlanComponent,
    PlanBigEventComponent,
    ExploreVenuesWrapperComponent,
    GeneralJwcComponent,
    ListFilterPipe,
    DateSuffixPipe,
    InquiryThankYouComponent,
    VenueHireCecComponent,
    VenueHireGardenComponent,
    Covid2SectionComponent,
    CulturalCentrePageComponent,
    CecServicePageComponent,
    AboutUsComponent,
    GardenSafetyComponent,
    UpgradeBrowserComponent,
    InquiryCareersComponent,
    StickyMenuComponent,
    LoginComponent,
    RegistrationComponent,
    OurWorldComponent,
    SafetyCardComponent,
    ExperiencesGardenSliderComponent,
    CampaignChatbotComponent,
    VideoPopupComponent,
    LegalPolicyTestComponent,
    DasComponent,
    WebsiteNotificationComponent,
    DasHeaderComponent,
    DasFooterComponent,
    NewsArticleComponent,
    CecEventDetailsComponent,
    GuestGuideComponent,
    SignInComponent,
    SignUpComponent,
    LoyaltyProgramComponent,
    LogisticsCecComponent,
    Instagram2SectionComponent,
    LogisticsServicesComponent,
    LogisticsRedirectComponent,
    BenefitsWrapperComponent,
    BackToWrapperComponent,
    BannerNewComponent,
    JioworldNitaAmbaniCCComponent,
    CareersComponent,
    DasNotificationsComponent,
   // EditNotificationsComponent
    CecEventComponent,
    NotificationComponent,
    CecWeddingEventComponent,
    CecWeddingDetailsComponent,
    CecVenueDetailsMeetingPackageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    RecaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgOtpInputModule,
    CKEditorModule
  ],
  providers: [Title, AppInitService,DatePipe,
    {​​​​ provide: APP_INITIALIZER, useFactory: init, deps: [AppInitService], multi: true }​​​​,
    {​​​​ provide: APP_INITIALIZER, useFactory: loadUrls, deps: [AppInitService], multi: true }​​​​ ,
  SafePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function init(appInitService: AppInitService) {​​​​ return () => appInitService.init();}​​​​
export function loadUrls(appInitService: AppInitService) {​​​​ return () => appInitService.loadUrls()};
