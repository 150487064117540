<div id="tempDiv"></div>
<form [formGroup]="VenueHireCecInquiryForm" InvalidControlScroll>
  <div class="row">
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{inquiryType}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="inquiryTypeValue.emit(item)" *ngFor="let item of inquiryData?.inquiryType">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{ brand }}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="brandValue.emit(item.value)" *ngFor="let item of venueCecData?.brand">{{item.label}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Halls & Rooms -->
    <div class="col-md-12">
      <div class="jwc_form_title">Choose a space that best fits your event</div>
      <div class="row jwc_form_group">
        <div class="col-md" *ngFor="let item of venueCecArrData?.spaces; let i = index">
          <div class="custom-control custom-checkbox checkbox jwc_form_group">
            <input type="checkbox" class="custom-control-input" [checked] = "typeOfHall == item" id="{{'checked'+(i+1)}}" name="dontmiss" value={{item}} (change)="onLayoutCheckboxClick('typeOfHall', $event)">
            <label class="custom-control-label" for="{{'checked'+(i+1)}}">{{item}}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Type of Events -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{typeOfEvent ? typeOfEvent : 'Type of Event'}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('typeOfEvent', item)" *ngFor="let item of venueCecArrData?.typeOfEvents">
                {{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- About Event -->
    <div class="col-md-12">
      <div class="jwc_form_group jwc_input_textarea">
        <input type="text" name="" value="" placeholder="Event Name" class="form-control jwc_input" id=""
          formControlName="eventNameInput">
        <textarea name="" id="" class="form-control jwc_textarea"
          placeholder="Please share a brief description about your event" cols="30" rows="10"
          formControlName="eventDescInput"></textarea>
      </div>
    </div>
    <!-- Event Date Time Picker -->
    <div class="col-md-12">
      <div class="jwc_form_title">Enter your event dates to know the availability of the venue</div>
      <div class="row" formGroupName="eventDateTime">
        <div class="col-lg">
          <div class="jwc_form_group jwc_date_time">
            <input type="text" name="" placeholder="From Date" value="From Date" 
              class="form-control jwc_input_date datepicker" id="eventFromDate" data-toggle="datepicker" #eventFromDate
              (click)="updateFromDate(eventFromDate.value, 'eventDateTime', 'fromDateInput')" formControlName="fromDateInput">
            <!-- <input type="text" class="form-control jwc_input_time timepicker" formControlName="fromTimeInput" 
              id="fromTime" #eventFromTime (click)="updateFromDate(eventFromTime.value, 'eventDateTime', 'fromTimeInput')"> -->
              <input type="text" name="" placeholder="End Date" value="End Date" 
              class="form-control jwc_input_date datepicker" id="eventToDate" data-toggle="datepicker"
              formControlName="endDateInput" #eventToDate (click)="updateFromDate(eventToDate.value, 'eventDateTime', 'endDateInput')">
          </div>
        </div>
        <div class="col-lg">
          <div class="jwc_form_group jwc_date_time">
            
            <!-- <input type="text" class="form-control jwc_input_time timepicker" value="2:30 AM" #eventToTime 
              formControlName="endTimeInput" (click)="updateFromDate(eventToTime.value, 'eventDateTime', 'endTimeInput')"> -->
          </div>
        </div>
      </div>
      <div *ngIf="eventDateError" class="error-text">{{eventDateError}}</div>
    </div>

     <!-- Setup Date Time Picker -->
     <div class="col-md-12">
      <div class="jwc_form_title">Choose a slot for the event set-up</div>
      <div class="row" formGroupName="setupDateTime">
        <div class="col-lg">
          <div class="jwc_form_group jwc_date_time">
            <input type="text" name="" placeholder="From Date" value="From Date" 
              class="form-control jwc_input_date datepicker" id="setupFromDate" data-toggle="datepicker" #setupFromDate
              (click)="updateFromDate(setupFromDate.value, 'setupDateTime', 'fromDateInput')" formControlName="fromDateInput">
            <!-- <input type="text" class="form-control jwc_input_time timepicker" formControlName="fromTimeInput" 
              id="fromTime" #setupFromTime (click)="updateFromDate(setupFromTime.value, 'setupDateTime', 'fromTimeInput')"> -->
              <input type="text" name="" placeholder="End Date" value="End Date" 
              class="form-control jwc_input_date datepicker" id="setupToDate" data-toggle="datepicker"
              formControlName="endDateInput" #setupToDate (click)="updateFromDate(setupToDate.value, 'setupDateTime', 'endDateInput')">
          </div>
        </div>
        <div class="col-lg">
          <div class="jwc_form_group jwc_date_time">
            
            <!-- <input type="text" class="form-control jwc_input_time timepicker" value="2:30 AM" #setupToTime 
              formControlName="endTimeInput" (click)="updateFromDate(setupToTime.value, 'setupDateTime', 'endTimeInput')"> -->
          </div>
        </div>
      </div>
      <div *ngIf="setupDateError" class="error-text">{{setupDateError}}</div>
    </div>

     <!-- Dismantle Date Time Picker -->
     <div class="col-md-12">
      <div class="jwc_form_title">Choose a slot for the event dismantling</div>
      <div class="row" formGroupName="dismantleDateTime">
        <div class="col-lg">
          <div class="jwc_form_group jwc_date_time">
            <input type="text" name="" placeholder="From Date" value="From Date" 
              class="form-control jwc_input_date datepicker" id="dismantleFromDate" data-toggle="datepicker" #dismantleFromDate
              (click)="updateFromDate(dismantleFromDate.value, 'dismantleDateTime', 'fromDateInput')" formControlName="fromDateInput">
              
              <input type="text" name="" placeholder="End Date" value="End Date" 
              class="form-control jwc_input_date datepicker" id="dismantleToDate" data-toggle="datepicker"
              formControlName="endDateInput" #dismantleToDate (click)="updateFromDate(dismantleToDate.value, 'dismantleDateTime', 'endDateInput')">
            <!-- <input type="text" class="form-control jwc_input_time timepicker" formControlName="fromTimeInput" 
              id="fromTime" #dismantleFromTime (click)="updateFromDate(dismantleFromTime.value, 'dismantleDateTime', 'fromTimeInput')"> -->
          </div>
        </div>
        <div class="col-lg">
          <div class="jwc_form_group jwc_date_time">
            
            <!-- <input type="text" class="form-control jwc_input_time timepicker" value="2:30 AM" #dismantleToTime 
              formControlName="endTimeInput" (click)="updateFromDate(dismantleToTime.value, 'dismantleDateTime', 'endTimeInput')"> -->
          </div>
        </div>
      </div>
      <div *ngIf="dismantleDateError" class="error-text">{{dismantleDateError}}</div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="jwc_form_group">
        <label>How many guests are you expecting?</label>
        <input type="text" name="" value="" placeholder="Maximum number of people" class="form-control jwc_input" id="" formControlName="numberOfPeopleInput" appAllowedInput [numbersOnly]="true">
      </div>
    </div>

    <div class="col-md-12">
      <div class="jwc_form_group jwc_range_slider_group">
        <label>Area required in sq.m.</label>
        <input class="jwc_range_slider" #areaRequired data-type="double" data-min="0" data-max="30000" data-from="300" data-to="2500"
          type="text" name="" value="" />
      </div>
    </div>
    <!-- Tentative Layout details -->
    <!-- <div class="col-md-12">
      <div class="jwc_form_title">
        Seating Type
      </div>
      <div class="row">
        <div class="col-md" *ngFor="let item of venueCecArrData?.seatingType; let i = index">
          <div class="custom-control custom-checkbox checkbox jwc_form_group">
            <input type="checkbox" class="custom-control-input" id="{{'checkedLayout'+(i+1)}}" name="dontmiss" value={{item}} (change)="onLayoutCheckboxClick('tentativeLayout', $event)">
            <label class="custom-control-label" for="{{'checkedLayout'+(i+1)}}">{{item}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="jwc_form_group jwc_form_input_inline">
        <label for="">Other, please specify</label>
        <input type="text" class="form-control" formControlName="otherLayoutInput">
      </div>
    </div>
    
    <div class="col-md-12" *ngFor="let item of venueCecArrData?.yesNoTitles; let i = index">
      <div class="jwc_form_title">{{item.title}}</div>
      <div class="row">
        <div class="col-md-12">
          <div class="jwc_radio_group">
            <div class="radio jwc_form_group">
              <input type="radio" class="" id="{{'yes' + i}}" value='Yes' name="radio{{i}}"
                (change)="onMiscRadioClick($event, item)">
              <label class="custom-control-label" for="{{'yes' + i}}">Yes</label>
            </div>
            <div class="radio jwc_form_group">
              <input type="radio" class="" id="{{'no' + i}}" value='No' name="radio{{i}}"
                (change)="onMiscRadioClick($event, item)">
              <label class="custom-control-label" for="{{'no' + i}}">No</label>
            </div>
          </div>

        </div>
      </div>
    </div> -->
    <!-- <div class="col-md-12">
      <div class="jwc_form_group jwc_form_input_inline">
        <label for="">Please share Artist details</label>
        <input type="text" class="form-control" formControlName="artistDetailInput">
      </div>
    </div> -->

    <!-- form title -->
    <div class="col-md-12">
      <div class="jwc_form_title">
        A few more details and you’ll be all set. 
      </div>
    </div>

    <!-- Name -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Name" class="form-control jwc_input" id=""
          formControlName="nameInput" appAllowedInput [alphabetsOnly]="true">
      </div>
      <div
        *ngIf="(venueHireCecFormControls.nameInput.touched || submitted) && venueHireCecFormControls.nameInput.errors">
        <div *ngIf="venueHireCecFormControls.nameInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>

    <!--Organiser Company -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Organiser Company" class="form-control jwc_input" id=""
          formControlName="companyInput">
      </div>
    </div>
    <!--Designation-->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Designation" class="form-control jwc_input" id=""
          formControlName="designationInput">
      </div>
    </div>

    <!--Organiser Email -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="email" name="" value="Juraij@gmail.com" placeholder="Organiser Email Address"
          class="form-control jwc_input" id="" formControlName="emailInput">
      </div>
      <div
        *ngIf="(venueHireCecFormControls.emailInput.touched || submitted) && venueHireCecFormControls.emailInput.errors">
        <div *ngIf="venueHireCecFormControls.emailInput.errors.required" class="error-text">{{errorText}}</div>
        <div *ngIf="venueHireCecFormControls.emailInput.errors.pattern" class="error-text">Email must be a valid email
          address
        </div>
      </div>
    </div>

    <!--Organiser contact number-->
    <div class="col-md-6">
      <div class="jwc_form_group jwc_form_phn">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">+91</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('countryCode', item)" *ngFor="let item of venueCecArrData?.countryCode">
                {{item}}</li>
            </ul>
          </div>
        </div>
        <input type="text" name="" value="" placeholder="Organiser contact number" class="form-control jwc_input" id=""
          formControlName="phoneNoInput" maxlength="10" appAllowedInput [numbersOnly]="true">
      </div>
      <div class="error-text"
        *ngIf="(venueHireCecFormControls.phoneNoInput.touched || submitted) && venueHireCecFormControls.phoneNoInput.errors">
        Enter 10 digit Mobile Number</div>
    </div>
    <!-- Client's company Name -->
    <div class="col-md-6">
      <!-- <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Client's Company Name" class="form-control jwc_input" id=""
          formControlName="clientCompanyInput">
      </div> -->
    </div>
    <!-- <div class="col-md-6">
      <div class="jwc_form_group jwc_form_phn">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">+91</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('clientCountryCode', item)" *ngFor="let item of venueCecArrData?.countryCode">
                {{item}}</li>
            </ul>
          </div>
        </div>
        <input type="text" name="" value="" placeholder="Client contact number" class="form-control jwc_input" id=""
          formControlName="clientPhoneNoInput" maxlength="10" appAllowedInput [numbersOnly]="true">
      </div>
      <div class="error-text"
        *ngIf="(venueHireCecFormControls.clientPhoneNoInput.touched || submitted) && venueHireCecFormControls.clientPhoneNoInput.errors">
        Enter 10 digit Mobile Number</div>
    </div> -->

    <!-- Message -->
    <div class="col-md-12">
      <div class="jwc_form_group">
        <textarea name="" id="" class="form-control jwc_textarea" placeholder="Message" cols="30" rows="10"
          formControlName="messageInput"></textarea>
      </div>
      <div *ngIf="(venueHireCecFormControls.messageInput.touched || submitted) && venueHireCecFormControls.messageInput.errors">
        <div *ngIf="venueHireCecFormControls.messageInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>

    <!-- T&C checkbox -->
    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input type="checkbox" class="custom-control-input" id="dontmiss" name="dontmiss" formControlName="tcCheckbox">
        <label class="custom-control-label" for="dontmiss"><b>By ticking the box, you accept our <a href="{{'/legal?tab=terms'}}" (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=terms', '_blank', 'External')">Terms &amp; Conditions</a></b></label>
      </div>
      <div class="error-text" *ngIf="submitted && venueHireCecFormControls.tcCheckbox.errors">{{errorText}}</div>
    </div>

     <!-- Recaptha code -->
     <div class="col-md-12 captcha">
      <re-captcha (resolved)="vCecCaptchaRes($event)" [siteKey]="captchaSiteKey"
          (error)="vCecCaptchaRes($event, true)" errorMode="handled"></re-captcha>
      <div class="error-text" style="margin-top:5px" *ngIf="submitted && !isCaptchaSelected">{{errorText}}</div>
    </div>
  </div>

  <div class="jwc_form_group--btn">
    <button type= "submit" class="cta-prim color-gold" [ngClass]="{'disabled':disableBtn}" [disabled]="disableBtn" (click)="venueHireCecSubmit(VenueHireCecInquiryForm)">
SUBMIT
    </button>
  </div>
  <div class="error-text" style="margin-top:5px" *ngIf="apiErrorMsg">{{apiErrorMsg}}</div>
</form>