<section class="benefits_wrapper_2 " [ngClass]="sectionClass" [attr.style]="('--data-background-color: ' + benefitsWrapperData?.ComponentBackgroundColor?.data ) | safe: 'style'">
    <div class="container">
      <div class="row align-items-center " [ngClass]="rowReverseClass ? 'flex-row-reverse' : ''">
        <div class="col-lg-7 col-md-7">
          <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#A67D54') | safe: 'style'">
            <!-- <div class="benefits_slider_each" *ngIf="benefitsWrapperData.cards[0]?.BackgroundImageVideo?.data !== 'image'; else image">
              <a href="javascript:;" class="watchVideoClick" data-target="#video_box" data-toggle="modal"
                  [attr.data-url]="staticUrlForImgNew + benefitsWrapperData.cards[0]?.UploadImageVideo?.document?.contentUrl">
                  <img src="{{ staticUrlForImgNew + benefitsWrapperData.cards[0]?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
                   class="img-fluid" alt="{{benefitsWrapperData.cards[0]?.ImageAltText?.data}}">

                  <div class="play_href default">
                    <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
                  </div>
              </a>
            </div> -->
            <video muted loop playsInline controlsList="nodownload" #video (canplay)="video.play()" (loadedmetadata)="video.muted = true" 
              class="d-block img-fluid"  poster="" autoplay *ngIf="benefitsWrapperData.cards[0]?.BackgroundImageVideo?.data !== 'image'; else image">
              <source src="{{staticUrlForImgNew + benefitsWrapperData.cards[0]?.UploadImageVideo?.document?.contentUrl}}" type="video/mp4">
              Your browser does not support HTML5 video.
            </video>
            <ng-template #image>
              <img src="{{ staticUrlForImgNew + benefitsWrapperData.cards[0]?.UploadImageVideo?.document?.contentUrl }}" class="img-fluid" alt="{{benefitsWrapperData.cards[0]?.ImageAltText?.data}}">
            </ng-template>
          </div>
        </div>
        <div class="col-lg-5 col-md-5">
          <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-lineColor:' + colorList?.benefitsLineColor) | safe: 'style'">
              <h2 [innerHTML]="benefitsWrapperData?.ComponentHeaderTitle?.data"></h2>
              <p *ngIf="benefitsWrapperData?.ComponentHeaderSubTitle?.data" [innerHTML]="benefitsWrapperData?.ComponentHeaderSubTitle?.data"></p>
          </div>
          <a *ngIf="rowReverseClass" href="/dhirubhai-ambani-square" class="cta-sec color-gold"><span class="cta-sec-txt">
            EXPLORE
        </span></a>  
        </div>
      </div>
    </div>
  </section>